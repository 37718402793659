<template>
  <v-dialog
    v-model="dialog"
    v-if="$store.getters['auth/isLoggedIn']"
    width="600"
  >
    <template v-slot:activator="{ on }">
      <v-btn small outlined v-on="on" class="ml-2" @click="online = false">
        {{ $t("pay") }}
      </v-btn>
    </template>
    <v-card id="select_token_payment">
      <v-card-title>
        {{ $t("invoices.to") }}
      </v-card-title>
      <v-card-subtitle class="mt-0 text-center primary--text">
        {{ $t("who_pays") }}
      </v-card-subtitle>

      <div class="close">
        <v-btn
          icon
          @click="
            dialog = false;
            user_id = null;
          "
          small
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="back" v-if="user_id">
        <v-btn icon @click="user_id = null" small>
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-row
          v-if="!user_id && $store.getters['auth/isStudio']"
          justify="center"
        >
          <v-col md="4">
            <base-border-card
              :title="$tc('studio')"
              @card_clicked="user_id = appointment.studio_id"
            ></base-border-card>
          </v-col>
          <v-col md="4">
            <base-border-card
              :title="$tc('artist')"
              @card_clicked="user_id = appointment.tattooer_id"
            ></base-border-card>
          </v-col>
        </v-row>
        <v-row v-else-if="online" justify="center">
          <v-col md="4">
            <base-border-card
              :title="$t('expenses.stripe')"
              @card_clicked="link_dialog = true"
              :disabled="
                !hasOnline ||
                ($store.getters['auth/isStudio'] &&
                  user_id !== appointment.studio_id)
              "
            >
            </base-border-card>
          </v-col>
          <v-col md="4">
            <base-border-card
              :title="$t('expenses.other')"
              @card_clicked="pay(pm)"
              :disabled="!hasOnline"
            >
            </base-border-card>
          </v-col>
        </v-row>
        <v-row v-else justify="center">
          <v-col md="4" v-for="(pm, i) in payment_methods" :key="i">
            <base-border-card
              :title="$t('expenses.' + pm)"
              @card_clicked="pay(pm)"
            >
            </base-border-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-dialog
        width="400"
        v-model="link_dialog"
        v-if="$store.getters['auth/isLoggedIn']"
      >
        <v-card id="consentFormLink">
          <v-card-title
            style="text-decoration: underline; text-transform: uppercase"
          >
            Vista del cliente:
          </v-card-title>
          <div class="close">
            <v-btn icon @click="link_dialog = false">
              <v-icon id="iconClose">mdi-close</v-icon>
            </v-btn>
          </div>
          <v-card-text class="pb-2">
            <v-text-field outlined dense id="URL" readonly v-model="URL">
              <template slot="prepend-inner">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon class="mr-2" v-on="on" @click="copy()">
                      mdi-content-copy
                    </v-icon>
                  </template>
                  {{ $t("copy") }}
                </v-tooltip>
              </template>
            </v-text-field>
          </v-card-text>
          <v-card-actions class="pt-0">
            <v-spacer />
            <v-btn
              style="color: #363533; height: 25px; padding-top: 10px !important"
              elevation="0"
              color="primary"
              @click="goToPay()"
            >
              {{ $t("to_complete") }}
            </v-btn>
            <v-btn
              style="color: #363533; height: 25px; padding-top: 10px !important"
              elevation="0"
              type="submit"
              @click="openSend()"
              ><v-icon style="margin-right: 5px" size="14px">$send</v-icon>
              {{ $t("send", { name: "" }) }}
            </v-btn>
            <SendMail ref="sendMail" hidden :appointment="appointment" />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-dialog>

  <v-btn
    v-else
    small
    outlined
    @click="
      $router.push({
        name: 'customer_payment',
        params: {
          token: appointment.studio.token,
          appointment_id: appointment.id,
        },
      })
    "
  >
    {{ $t("pay") }}
  </v-btn>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      payment_methods: ["cash", "card", "online"],
      link_dialog: false,
      user_id: null,
      online: false,
    };
  },
  props: ["appointmentTokenPayment", "appointment"],
  components: {
    SendMail: () => import("@/components/mails/SendMail"),
  },
  computed: {
    URL() {
      if (this.$store.getters["auth/isStudio"])
        return `${window.location.origin}/?#/inscription/studio/${this.appointment.studio.token}/appointment/${this.appointment.id}/appointment_token/${this.appointmentTokenPayment.id}`;
      else
        return `${window.location.origin}/?#/inscription/studio/${this.appointment.tattooer.token}/appointment/${this.appointment.id}/appointment_token/${this.appointmentTokenPayment.id}`;
    },
    hasOnline() {
      console.log("hasOnline", this.appointment);
      let s = this.appointment.studio.user.setting.find(
        (x) => x.key === "pm_stripe_id"
      );
      if (s) return s.value !== 0;
      return false;
    },
  },
  methods: {
    copy() {
      /* Get the text field */
      var copyText = document.getElementById("URL");

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /*For mobile devices*/

      /* Copy the text inside the text field */
      document.execCommand("copy");
      this.link_dialog = false;
      this.$alert(this.$t("link_copy_success"), "", "");
    },
    ...mapActions("appointments", ["generateAppointmentTokenPayment"]),
    ...mapActions("expenses", ["confirmExpense"]),
    pay(pm) {
      if (pm === "online" && !this.online) this.online = true;
      else {
        this.generateAppointmentTokenPayment({
          id: this.appointmentTokenPayment.id,
          method: pm,
          user_id: this.user_id,
        }).then((response) => {
          this.dialog = false;
          if (response.success) {
            this.$emit("update");
            this.$alert(this.$t("notifications.types.payed"));
          } else {
            this.$alert(this.$t("not_enouth_money"));
          }
        });
      }
    },
    openSend() {
      this.$refs.sendMail.open("PAGA Y SEÑAL");
      console.log(this.appointmentTokenPayment.id);
      this.$refs.sendMail.signal_selected = this.appointmentTokenPayment.id;
    },
    goToPay() {
      this.$router.push({
        name: "customer_payment",
        params: {
          token: this.appointment.studio.token,
          appointment_id: this.appointment.id,
          appointment_token_id: this.appointmentTokenPayment.id,
        },
      });
    },
  },
};
</script>

<style lang="sass">
#select_token_payment
  .v-card--disabled
    border-color: var(--v-gris1-base)
    .v-card__title
      color: var(--v-gris1-base)
    @media(max-width: 600px)
      height: 100px
</style>
