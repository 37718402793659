var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$store.getters['auth/isLoggedIn'])?_c('v-dialog',{attrs:{"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"small":"","outlined":""},on:{"click":function($event){_vm.online = false}}},on),[_vm._v(" "+_vm._s(_vm.$t("pay"))+" ")])]}}],null,false,1970372827),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"id":"select_token_payment"}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("invoices.to"))+" ")]),_c('v-card-subtitle',{staticClass:"mt-0 text-center primary--text"},[_vm._v(" "+_vm._s(_vm.$t("who_pays"))+" ")]),_c('div',{staticClass:"close"},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.dialog = false;
          _vm.user_id = null;}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),(_vm.user_id)?_c('div',{staticClass:"back"},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.user_id = null}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1)],1):_vm._e(),_c('v-card-text',[(!_vm.user_id && _vm.$store.getters['auth/isStudio'])?_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"4"}},[_c('base-border-card',{attrs:{"title":_vm.$tc('studio')},on:{"card_clicked":function($event){_vm.user_id = _vm.appointment.studio_id}}})],1),_c('v-col',{attrs:{"md":"4"}},[_c('base-border-card',{attrs:{"title":_vm.$tc('artist')},on:{"card_clicked":function($event){_vm.user_id = _vm.appointment.tattooer_id}}})],1)],1):(_vm.online)?_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"4"}},[_c('base-border-card',{attrs:{"title":_vm.$t('expenses.stripe'),"disabled":!_vm.hasOnline ||
              (_vm.$store.getters['auth/isStudio'] &&
                _vm.user_id !== _vm.appointment.studio_id)},on:{"card_clicked":function($event){_vm.link_dialog = true}}})],1),_c('v-col',{attrs:{"md":"4"}},[_c('base-border-card',{attrs:{"title":_vm.$t('expenses.other'),"disabled":!_vm.hasOnline},on:{"card_clicked":function($event){return _vm.pay(_vm.pm)}}})],1)],1):_c('v-row',{attrs:{"justify":"center"}},_vm._l((_vm.payment_methods),function(pm,i){return _c('v-col',{key:i,attrs:{"md":"4"}},[_c('base-border-card',{attrs:{"title":_vm.$t('expenses.' + pm)},on:{"card_clicked":function($event){return _vm.pay(pm)}}})],1)}),1)],1),(_vm.$store.getters['auth/isLoggedIn'])?_c('v-dialog',{attrs:{"width":"400"},model:{value:(_vm.link_dialog),callback:function ($$v) {_vm.link_dialog=$$v},expression:"link_dialog"}},[_c('v-card',{attrs:{"id":"consentFormLink"}},[_c('v-card-title',{staticStyle:{"text-decoration":"underline","text-transform":"uppercase"}},[_vm._v(" Vista del cliente: ")]),_c('div',{staticClass:"close"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.link_dialog = false}}},[_c('v-icon',{attrs:{"id":"iconClose"}},[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"pb-2"},[_c('v-text-field',{attrs:{"outlined":"","dense":"","id":"URL","readonly":""},model:{value:(_vm.URL),callback:function ($$v) {_vm.URL=$$v},expression:"URL"}},[_c('template',{slot:"prepend-inner"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",on:{"click":function($event){return _vm.copy()}}},on),[_vm._v(" mdi-content-copy ")])]}}],null,false,2496974216)},[_vm._v(" "+_vm._s(_vm.$t("copy"))+" ")])],1)],2)],1),_c('v-card-actions',{staticClass:"pt-0"},[_c('v-spacer'),_c('v-btn',{staticStyle:{"color":"#363533","height":"25px","padding-top":"10px !important"},attrs:{"elevation":"0","color":"primary"},on:{"click":function($event){return _vm.goToPay()}}},[_vm._v(" "+_vm._s(_vm.$t("to_complete"))+" ")]),_c('v-btn',{staticStyle:{"color":"#363533","height":"25px","padding-top":"10px !important"},attrs:{"elevation":"0","type":"submit"},on:{"click":function($event){return _vm.openSend()}}},[_c('v-icon',{staticStyle:{"margin-right":"5px"},attrs:{"size":"14px"}},[_vm._v("$send")]),_vm._v(" "+_vm._s(_vm.$t("send", { name: "" }))+" ")],1),_c('SendMail',{ref:"sendMail",attrs:{"hidden":"","appointment":_vm.appointment}})],1)],1)],1):_vm._e()],1)],1):_c('v-btn',{attrs:{"small":"","outlined":""},on:{"click":function($event){return _vm.$router.push({
      name: 'customer_payment',
      params: {
        token: _vm.appointment.studio.token,
        appointment_id: _vm.appointment.id,
      },
    })}}},[_vm._v(" "+_vm._s(_vm.$t("pay"))+" ")])}
var staticRenderFns = []

export { render, staticRenderFns }